<template>
	<div>
		<div class="page">
			<div class="container">
				<div class="main">
					<div class="left">
						<h2>广告</h2>
					</div>
					<div class="right">
						<div class="box">
							<div class="box-item"><h2>文件打印</h2></div>
							<div class="box-item"><h2>照片打印</h2></div>
							<div class="box-item"><h2>身份证</h2></div>
							<div class="box-item"><H2>文件复印</H2></div>
						</div>
						<div class="box">
							<h2>个人中心</h2>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>





<script>

	export default {

		data() {
			return {


			}
		},
		created() {


		},
		methods: {

			//处理证件

		},
	}
</script>


<style lang="scss" scoped>
	
	.main{
		width:70%;
		height:500px;
		background:red;
		
		display:flex;
		justify-content: space-between;
		margin: 0 auto;
		margin-top:100px;
		.left{
			flex:2;
			background: blue;
		}
		
		.right{
			flex:3;
			background-color: yellow;
			display:flex;
			justify-content: space-between;
			flex-direction:column; 
			.box:nth-child(1){
				flex:2;
				background:#ccc;
				display:flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.box-item{
					width:48%;
					background:#fff;
					margin:1%;
				}
				
			}
			
			.box:nth-child(2){
				flex:1;
				background:pink;
			}
		}
		
	}
	
	
	
</style>
